import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Authentications/Login/Login";
import Register from "./components/Authentications/Registration/Register";
import UserDashboard from "./components/Dashboard/UserDashboard";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";


// TODO: secure dashboard by user-authentication, auto-jump to login
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route path='/user-dashboard' element={<UserDashboard />} />

        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
