import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import NavBar from "../../utils/Navbar/NavBar";
import config from "../../configs";

const About = () => {

    return (
        <div className='login-page'>
            <NavBar />
            <h1>About Page</h1>
        </div>
    );
};

export default About;