import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import NavBar from "../../../utils/Navbar/NavBar";
import ProgressDots from '../../../utils/ProgressDots/ProgressDots';
import './Register.css';

// Components for each registration step
// First component
const WelcomeComponent = ({ onNext, formData, handleChange }) => (
    <div className="welcome">
        <div className='left-side'> 
            <h1>Welcome to DAZZR.AI</h1>
            <p>Login to get started with Dazzr.ai. If not yet registered, let’s get started here.</p>
            <ProgressDots currentStep={1} totalSteps={3} />
        </div>
        <div className='signup-form'>
            <form onSubmit={(e) => { e.preventDefault(); onNext(); }} className='register-form'>
                <h1>Sign Up New Account</h1>
                <p>Username</p>
                <input type="text" name="username" value={formData.username} onChange={handleChange} required />
                <p>Email</p>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                <p>Phone Number (Optional)</p>
                <input type="tel" name="phone" value={formData.phone} onChange={handleChange} />
                <p>Password</p>
                <input type="password" name="password" value={formData.password} onChange={handleChange} required />
                <button type="submit">Create An Account</button>
                <p>Already have an account? <Link to="/">Login</Link></p>
            </form>
        </div>
    </div>
);


// Second component
const SignUpComponent = ({ onNext, formData, handleChange }) => (
    <div className="sign-up">
        <div className='left-side'> 
            <h1>Transforming the Way You Shop for Cloths</h1>
            <p>We aim to provide you with the most personalized apparel shopping experience, while your privacy is what we valued the most. </p>
            <ProgressDots currentStep={2} totalSteps={3} />
        </div>
        <div className="more-info">
            <form onSubmit={(e) => { e.preventDefault(); onNext(); }} className='register-form'>
                <p>Age</p>
                <input type="number" name="age" value={formData.age} onChange={handleChange} required />
                <p>Gender</p>
                <input type="text" name="gender" value={formData.gender} onChange={handleChange} required />
                <p>Ethnicity</p>
                <input type="text" name="ethnicity" value={formData.ethnicity} onChange={handleChange} required />
                <p>How did you learn about us? (Optional)</p>
                <input type="text" name="referral_source" value={formData.referral_source} onChange={handleChange} />
                <p>Zip Code</p>
                <input type="text" name="zip_code" value={formData.zip_code} onChange={handleChange} required />
                <button type="submit">Next Step</button>
            </form>
        </div>
    </div>
);


// Third component
const AdditionalInfoComponent = ({ onNext, formData, handleChange }) => {
  
    const categories = [
        { label: "Budget-Friendly Brands (Under $50)", value: "budget_friendly" },
        { label: "High-Street Brands ($50 - $150)", value: "high_street" },
        { label: "Contemporary Brands ($150 - $500)", value: "contemporary" },
        { label: "Accessible Luxury Brands ($500 - $1,000)", value: "accessible_luxury" },
        { label: "Luxury Brands (>$1,000)", value: "luxury" },
        { label: "Secondhand and/or Vintage Brands", value: "secondhand_vintage" }
    ];
    
    const brands = ["Reformation", "ZARA", "H&M", "Everlane", "lululemon", "SHEIN", "Patagonia", "ASOS", "GUCCI", "depop", "SSENSE", "The RealReal"];
    
    const handleCategoryChange = (category) => {
        const updatedCategories = formData.purchase_categories.includes(category)
          ? formData.purchase_categories.filter(item => item !== category)
          : [...formData.purchase_categories, category];
        handleChange({ target: { name: 'purchase_categories', value: updatedCategories } });
    };
    
    const handleBrandSelection = (brand) => {
        const updatedBrands = formData.brands_shopped.includes(brand)
          ? formData.brands_shopped.filter(item => item !== brand)
          : [...formData.brands_shopped, brand];
        handleChange({ target: { name: 'brands_shopped', value: updatedBrands } });
    };
  
    return (
        <div className="additional-info">
            <div className='left-side'> 
                <h1>Curate Your Personalized Shopping Experience</h1>
                <p>Do you know Dazzr.ai allow you to shop your best fits and explore new brands that amaze you? </p>
                <ProgressDots currentStep={3} totalSteps={3} />
            </div>
            <div className="brand-survey">
                {categories.map(category => (
                    <label key={category.value}>
                        <input
                        type="checkbox"
                        checked={formData.purchase_categories.includes(category.value)}
                        onChange={() => handleCategoryChange(category.value)}
                        />
                        {category.label}
                    </label>
                ))}

                <h2>Select brands that you have shopped before:</h2>
                <div className="brands">
                    {brands.map(brand => (
                    <span
                        key={brand}
                        className={formData.brands_shopped.includes(brand) ? "brand selected" : "brand"}
                        onClick={() => handleBrandSelection(brand)}
                    >
                        {brand}
                    </span>
                    ))}
                </div>
                <button type="button" onClick={onNext}>Next Step</button>
            </div>
        </div>
    );
};



const Register = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const handleNext = () => setCurrentStep(currentStep + 1);
    const handleBack = () => setCurrentStep(currentStep - 1);


    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        password: '',
        age: '',
        gender: '',
        ethnicity: '',
        referral_source: '',
        zip_code: '',
        purchase_categories: [],
        brands_shopped: [],
    });
    
    const navigate = useNavigate();
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value,
        }));
    };

    const handleSubmit = () => {
        console.log("Form Data Submitted:", formData);
        // TODO: add api endpoint call here
        navigate('/user-dashboard');
      };

    return (
        <div className='register'>
            <NavBar />
            <div className='register-page'>
                {currentStep === 1 && (
                    <WelcomeComponent
                        current={handleNext}
                        onNext={handleNext}
                        formData={formData}
                        handleChange={handleChange}
                    />
                )}
                {currentStep === 2 && (
                    <SignUpComponent
                        onBack={handleBack}
                        onNext={handleNext}
                        formData={formData}
                        handleChange={handleChange}
                    />
                )}
                {currentStep === 3 && (
                    <AdditionalInfoComponent
                        onBack={handleBack}
                        onNext={handleSubmit}
                        formData={formData}
                        handleChange={handleChange}
                    />
                )}
            </div>
        </div>
    );
};

export default Register;
