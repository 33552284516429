import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css';
import NavBar from "../../../utils/Navbar/NavBar";
import config from "../../../configs";

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${config.apiBaseUrl}${config.endpoints.login}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });
            
            // Get api endpoint's response, put 2 jwt tokens into cookie.
            const data = await response.json();
            if (response.ok) {
                console.log('Login Success:', data);
                
                document.cookie = `accessToken=${data.access}; path=/; SameSite=Strict`;
                document.cookie = `refreshToken=${data.refresh}; path=/; SameSite=Strict`;

                navigate('/user-dashboard');
            } else {
                throw new Error(data.detail || 'Failed to login');
            }

        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    return (
        <div className='login'>
            <NavBar />
            <div className='login-page'>
                <div className='description'>
                    <h1>Welcome to DAZZR.AI</h1>
                    <p>Login to your profile. If have not registered,</p>
                    <p>please create an account <Link to="/register">here</Link></p>
                </div>
                <div className='login-window'>
                    <form onSubmit={handleSubmit} className='login-form'>
                        <h2>Log In</h2>
                        <p>User Name</p>
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                        <p>Password</p>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        <button type="submit">Log In</button>
                    </form>
                    <p>HAVE NOT CREATED AN ACCOUNT? <Link to="/register">Register Here</Link></p>
                </div>
            </div>
        </div>
    );
};

export default Login;
