const dev = {
    apiBaseUrl: 'http://localhost:3550',
    // apiBaseUrl: 'http://54.151.67.121:8867',
    endpoints: {
      register: '/users/register/',
      login: '/users/login/',
      userDetail: '/users/me/',
      // updateMeasurements: '/users/update_measurements/',
      logout: '/users/logout/',
    }
};

const prod = {
    apiBaseUrl: 'http://ideally-precise-macaque.ngrok-free.app',
    endpoints: {
      register: '/users/register/',
      login: '/users/login/',
      userDetail: '/users/me/',
      // updateMeasurements: '/users/update_measurements/',
      logout: '/users/logout/',
    }
};
  
const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
