import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import "./NavBar.css";
import vertical_bar from "../../images/vertical_bar.png";

const NavBar = () => {
    
    const navigate = useNavigate();

    return (
        <div className='navigation-bar'>
            <div className='company-icon'>
                {/* <img/> */}
                <h1>DAZZR.AI</h1>
            </div>
            <div className='navigators'>
                <Link to="/about" className="about-link">About</Link>
                <Link to="/contact" className="contact-link">Contact</Link>
                <img className="vertical_bar" src={vertical_bar} alt='vertical_bar'/>
                <Link to="/" style={{color: '#4D4DDE' }} className="login-link">Log in</Link>
                <button className="registration-button" onClick={() => navigate("/register")}>
                    Start for free
                </button>
            </div>
        </div>
    );
};

export default NavBar;

