import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import NavBar from "../../utils/Navbar/NavBar";
import config from "../../configs";

const userDashboard = () => {

    return (
        <div className='userDashboard-page'>
            <NavBar />
            <h1>User Dashboard Page</h1>
        </div>
    );
};

export default userDashboard;