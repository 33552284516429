import React from 'react';
import "./ProgressDots.css";

const ProgressDots = ({ currentStep, totalSteps }) => {
    return (
        <div className="progress-dots">
            {[...Array(totalSteps)].map((_, index) => (
                <div 
                    key={index}
                    className={currentStep === index + 1 ? 'active' : ''}
                />
            ))}
        </div>
    );
};

export default ProgressDots;
